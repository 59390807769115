const DEFAULT_TRANSLATION = 'UA';

export const getLanguage = () => {
  return DEFAULT_TRANSLATION;
};

const translations = {
  UA: () => import('./ua'),
  EN: () => import('./en'),
  FI: () => import('./fi'),
  PL: () => import('./pl')
};

const chosenLanguage = getLanguage();
const loadTranslation = translations[chosenLanguage] || translations[DEFAULT_TRANSLATION];

const chosenTranslation = await loadTranslation().then((module) => module.default);

export default chosenTranslation;
