import config from 'config';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import reducer from 'reducers';
import { IS_SNAP } from 'variables/main';

const {
  application: { environment }
} = config;

const createStore = () => {
  const middlewares = [routerMiddleware(), thunk];

  if (environment !== 'prod') {
    const { createLogger } = require('redux-logger');
    middlewares.push(environment !== 'prod' && !IS_SNAP && createLogger({ collapsed: true }));
  }

  return configureStore({
    reducer,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
    middleware: () => middlewares.filter(Boolean),
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  });
};

export default createStore;
