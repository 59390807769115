import MobileDetect from 'mobile-detect';

const isMobile = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isSm = !!md.mobile() || !!md.tablet() || window.innerWidth <= 1024;
  return isSm;
};

const initialState = {
  openDrawer: false,
  user: null,
  isMobile: isMobile()
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPEN_DRAWER':
      return { ...state, openDrawer: action.payload };
    case 'SET_LOCAL_SIGN_IN_REDIRECT':
      return { ...state, localSignInRedirect: action.payload };
    case 'SET_USER':
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
